import { Context, Middleware } from '@nuxt/types'

const TWO_POINT_O_PATHS = [
  // IE
  '/en/ie/bitsa',
  '/en-gb/ie/bitsa',
  '/en/ie/neosurf',
  '/en-gb/ie/neosurf',
  '/en/ie/treatwell',
  '/en-gb/ie/treatwell',
  '/en/ie/nintendo-eshop',
  '/en-gb/ie/nintendo-eshop',
  '/en/ie/cashlib',
  '/en-gb/ie/cashlib',
  '/en/ie/mifinity',
  '/en-gb/ie/mifinity',
  '/en/ie/toneo-first',
  '/en-gb/ie/toneo-first',
  '/en/ie/xbox-gift-card',
  '/en-gb/ie/xbox-gift-card',
  '/en/ie/playstation-store',
  '/en-gb/ie/playstation-store',
  '/en/ie/aplauz',
  '/en-gb/ie/aplauz',
  '/en/ie/twitch',
  '/en-gb/ie/twitch',
  '/en/ie/mobile-top-up',
  '/en-gb/ie/mobile-top-up',
  '/en/ie/pcs',
  '/en-gb/ie/pcs',
  '/en/ie/transcash',
  '/en-gb/ie/transcash',
  '/en/ie/just-eat',
  '/en-gb/ie/just-eat',
  '/en/ie/eir-mobile',
  '/en-gb/ie/eir-mobile',
  '/en/ie/lycamobile',
  '/en-gb/ie/lycamobile',
  '/en/ie/paysafecard',
  '/en-gb/ie/paysafecard',
  '/en/ie/rewarble-perfect-money',
  '/en-gb/ie/rewarble-perfect-money',
  '/en/ie/three',
  '/en-gb/ie/three',
  // GB
  '/en/gb/neosurf',
  '/en-gb/gb/neosurf',
  '/de/gb/neosurf',
  '/fr/gb/neosurf',
  '/it/gb/neosurf',
  '/es/gb/neosurf',
  '/en/gb/lebara',
  '/en-gb/gb/lebara',
  '/en/gb/lycamobile',
  '/en-gb/gb/lycamobile',
  '/en/gb/just-eat',
  '/en-gb/gb/just-eat',
  '/en/gb/giffgaff',
  '/en-gb/gb/giffgaff',
  '/en-gb/gb/vodafone',
  '/en/gb/vodafone',
  '/en/gb/three',
  '/en-gb/gb/three',
  '/en/gb/ee-mobile',
  '/en/gb/o2-mobile',
  '/en/gb/paysafecard',
  '/en/gb/playstation-store',
  '/en/gb/steam',
  '/en/gb/tesco-mobile',
  '/en/gb/transcash',
  '/en-gb/gb/ee-mobile',
  '/en-gb/gb/o2-mobile',
  '/en-gb/gb/paysafecard',
  '/en-gb/gb/playstation-store',
  '/en-gb/gb/steam',
  '/en-gb/gb/tesco-mobile',
  '/en-gb/gb/transcash',
  '/en-gb/gb/airbnb',
  '/en/gb/airbnb',
  '/en-gb/gb/flexepin',
  '/en/gb/flexepin',
  '/en-gb/gb/cashlib',
  '/en/gb/cashlib',
  '/en-gb/gb/cashtocode',
  '/en/gb/cashtocode',
  '/en-gb/gb/lush',
  '/en/gb/lush',
  '/en-gb/gb/nintendo-eshop',
  '/en/gb/nintendo-eshop',
  '/en-gb/gb/mifinity',
  '/en/gb/mifinity',
  '/en-gb/gb/candy-crush',
  '/en/gb/candy-crush',
  '/en-gb/gb/league-of-legends',
  '/en/gb/league-of-legends',
  '/en-gb/gb/xbox-gift-card',
  '/en/gb/xbox-gift-card',
  '/en-gb/gb/toneo-first',
  '/en/gb/toneo-first',
  '/en-gb/gb/nintendo-switch-online',
  '/en/gb/nintendo-switch-online',
  '/en-gb/gb/twitch',
  '/en/gb/twitch',
  '/en-gb/gb/lastminute',
  '/en/gb/lastminute',
  '/en-gb/gb/meta-quest',
  '/en/gb/meta-quest',
  '/en-gb/gb/treatwell',
  '/en/gb/treatwell',
  '/en-gb/gb/talk-home-mobile',
  '/en/gb/talk-home-mobile',
  '/en-gb/gb/now-mobile',
  '/en/gb/now-mobile',
  // NZ
  '/en/nz/paysafecard',
  '/en-gb/nz/paysafecard',
  '/en/nz/neosurf',
  '/en-gb/nz/neosurf',
  '/en/nz/cashtocode',
  '/en-gb/nz/cashtocode',
  '/en/nz/flexepin',
  '/en-gb/nz/flexepin',
  '/en/nz/cashlib',
  '/en-gb/nz/cashlib',
  '/en/nz/mifinity',
  '/en-gb/nz/mifinity',
  // CA
  '/en/ca/mobile-top-up',
  '/en-gb/ca/mobile-top-up',
  '/en/ca/lucky-mobile',
  '/en-gb/ca/lucky-mobile',
  '/en/ca/paysafecard',
  '/en-gb/ca/paysafecard',
  '/en/ca/freedom-mobile',
  '/en-gb/ca/freedom-mobile',
  '/en/ca/telus',
  '/en-gb/ca/telus',
  '/fr/ca/lucky-mobile',
  '/fr/ca/paysafecard',
  '/fr/ca/public-mobile',
  '/fr/ca/freedom-mobile',
  '/fr/ca/koodo-mobile',
  '/fr/ca/chatr-mobile',
  '/fr/ca/telus',
  '/fr/ca/virgin-mobile',
  '/en/ca/chatr-mobile',
  '/en-gb/ca/chatr-mobile',
  '/en/ca/koodo-mobile',
  '/en-gb/ca/koodo-mobile',
  '/en/ca/public-mobile',
  '/en-gb/ca/public-mobile',
  '/en/ca/virgin-mobile',
  '/en-gb/ca/virgin-mobile',
  '/en/ca/uber',
  '/en-gb/ca/uber',
  '/en/ca/airbnb',
  '/en-gb/ca/airbnb',
  '/en/ca/h-m',
  '/en-gb/ca/h-m',
  '/en/ca/spotify-premium',
  '/en-gb/ca/spotify-premium',
  '/en/ca/neosurf',
  '/en-gb/ca/neosurf',
  '/en/ca/xbox-gift-card',
  '/en-gb/ca/xbox-gift-card',
  '/en/ca/transcash',
  '/en-gb/ca/transcash',
  '/en/ca/cashlib',
  '/en-gb/ca/cashlib',
  '/en/ca/mifinity',
  '/en-gb/ca/mifinity',
  '/en/ca/cashtocode',
  '/en-gb/ca/cashtocode',
  '/en/ca/cineplex-com',
  '/en-gb/ca/cineplex-com',
  '/en/ca/mts-wireless',
  '/en-gb/ca/mts-wireless',
  // OTHERS
  '/en/au/neosurf',
  '/en-gb/au/neosurf',
  '/de/de/steam',
  '/en/de/steam',
  '/en-gb/de/steam',
  '/fr/de/steam',
  '/en/at/transcash',
  '/en-gb/at/transcash',
  '/de/at/transcash',
  '/en/at/abon',
  '/en-gb/at/abon',
  '/de/at/abon',
  '/de/at/steam',
  '/en/at/steam',
  '/en-gb/at/steam',
  '/en/at/paysafecard',
  '/en-gb/at/paysafecard',
  '/de/at/paysafecard',
  '/fr/fr/pcs',
  '/en/fr/pcs',
  '/en-gb/fr/pcs',
  '/it/it/paysafecard',
  '/en/it/paysafecard',
  '/en-gb/it/paysafecard',
  '/en/ch/transcash',
  '/en-gb/ch/transcash',
  '/de/ch/transcash',
  '/fr/ch/transcash',
  '/en/ch/paysafecard',
  '/en-gb/ch/paysafecard',
  '/fr/ch/paysafecard',
  '/de/ch/paysafecard',
  '/es/es/paysafecard',
  '/en/es/paysafecard',
  '/en-gb/es/paysafecard',
  '/en/at/paysafecard',
  '/en/at/abon',
  '/en/at/transcash',
  '/en/mx/telcel',
  '/en-gb/mx/telcel',
  '/en-gb/at/paysafecard',
  '/en-gb/at/abon',
  '/en-gb/at/transcash',
  '/de/at/paysafecard',
  '/de/at/abon',
  '/de/at/transcash',
  '/au/pcs',
  '/au/paysafecard',
  '/au/cashlib',
  '/au/transcash',
  '/au/mifinity',
  '/au/cashtocode',
  '/de/tiktok',
  '/de/rtl-plus',
  '/de/aplauz',
  '/de/bitsa',
  '/de/cashlib',
  '/de/just-eat',
  '/de/mifinity',
  '/de/pcs',
  '/de/razer-gold',
  '/de/toneo-first',
  '/de/paysafecard-players-pass',
  '/de/paysafecard',
  '/at/pcs',
  '/at/toneo-first',
  '/at/kobo-rakuten',
  '/at/flexepin',
  '/at/aplauz',
  '/at/neosurf',
  '/at/cashlib',
  '/at/ea-origin',
  '/at/just-eat',
  '/at/bitsa',
  '/at/mifinity',
  '/at/cashtocode',
  '/fr/karma-koin',
  '/fr/flexepin',
  '/fr/cashlib',
  '/fr/mifinity',
  '/fr/cashtocode',
  '/fr/neosurf',
  '/fr/paysafecard',
  '/fr/airbnb',
  '/fr/primark',
  '/fr/treatwell',
  '/fr/uber',
  '/fr/nintendo-eshop',
  '/fr/nintendo-games',
  '/fr/playstation-plus',
  '/fr/roblox',
  '/it/pcs',
  '/it/toneo-first',
  '/it/mint-prepaid',
  '/it/aplauz',
  '/it/neosurf',
  '/it/ea-sports',
  '/it/cashlib',
  '/it/disney-plus',
  '/it/blizzard',
  '/it/ea-origin',
  '/it/tiktok',
  '/it/mifinity',
  '/it/bitsa',
  '/it/flexepin',
  '/ch/toneo-first',
  '/ch/aplauz',
  '/ch/cashlib',
  '/ch/just-eat',
  '/ch/bitsa',
  '/ch/mifinity',
  '/ch/flexepin',
  '/ch/neosurf',
  '/ch/pcs',
  '/ch/h-m',
  '/ch/nintendo-eshop',
  '/ch/nintendo-switch-online',
  '/ch/playstation-store',
  '/ch/roblox',
  '/ch/treatwell',
  '/ch/xbox-gift-card',
  '/pl/pcs',
  '/pl/abon',
  '/pl/toneo-first',
  '/pl/allegro',
  '/pl/paysafecard',
  '/pl/flexepin',
  '/pl/neosurf',
  '/pl/bigpoint',
  '/pl/cashlib',
  '/pl/the-settlers-online',
  '/pl/bitsa',
  '/pl/transcash',
  '/pl/mifinity',
  '/pl/netflix',
  '/pl/nintendo-eshop',
  '/pl/nintendo-switch-online',
  '/pl/roblox',
  '/pl/xbox-gift-card',
  '/es/pcs',
  '/es/abon',
  '/es/toneo-first',
  '/es/mint-prepaid',
  '/es/kobo-rakuten',
  '/es/flexepin',
  '/es/aplauz',
  '/es/neosurf',
  '/es/cashlib',
  '/es/disney-plus',
  '/es/blizzard',
  '/es/ea-origin',
  '/es/bitsa',
  '/es/transcash',
  '/es/mifinity',
]

const routeToTwoPointO: Middleware = ({ next, route }: Context) => {
  if (process.server) return

  if (TWO_POINT_O_PATHS.some(path => route.path.includes(path))) {
    window.location.href = route.path
    next?.(false)
  }
}

export default routeToTwoPointO
